module.exports = {
	fr: {
		// SETTINGS PAGE
		settings_title: "Paramètres",
		lang_title: "Choisir votre langage",
		// SERVER SIDEBAR PAGE
		title_servers: "Liste des serveurs",
		name_servers_sidebar: "SERVEURS",
		acces_shop: "Accèder à la boutique",
		premium_text:
			"Vous devez être premium pour utiliser cette outils",
		close: "Fermer",
		validate: "Valider",
		add_servers: "Ajouter un Serveur",
		// BOT SIDEBAR PAGE
		title_bot: "Liste de Bot",
		name_bot_sidebar: "BOT",
		// NAVBAR
		home: "Accueil",
		store: "Boutique",
		add_solde: "Ajouter du solde",
		faq: "Faq",
		settings: "Paramètres",
		solde: "Solde",
		logout: "Se déconnecter",
		// ADD-CREDIT
		buy_credit_title: "Achat de Crédit",
		add_credit_0: "Ajouter",
		add_credit_1: "de crédit",
		deny_request:
			"Non je ne souhaite pas acheter de crédit.",
		//STATS
		actif_member: "Utilisateurs",
		bot_count: "Bots en circulation",
		payload_count: "Payloads Lancée",
		//SHOP PAGE
		pack_premium_title: "Premium",
		pack_premium_text: "La version premium vous permet d'utiliser nos services. Nous vous proposons plusieurs options pour gérer efficacement vos bots. Il s'agit d'un paiement à vie.",
		buy: "Acheter",
		already_buy: "Déjà acheté",
		pack_bot_title: "Discord Bot",
		pack_bot_text: "Distroy vous fournit des bots de très bonne qualité vérifiés par email et par téléphone.",
	},
	gb: {
		// SETTINGS PAGE
		settings_title: "Settings",
		lang_title: "Choose your language",
		// SERVER SIDEBAR PAGE
		title_servers: "List of servers",
		name_servers_sidebar: "SERVERS",
		acces_shop: "Access to the store",
		premium_text: "You must be premium to use this tool",
		close: "Close",
		validate: "Validate",
		add_servers: "Add a Server",
		// BOT SIDEBAR PAGE
		title_bot: "List of Bot",
		name_bot_sidebar: "BOT",
		// NAVBAR
		home: "Home",
		store: "Store",
		add_solde: "Add balance",
		faq: "Faq",
		settings: "Settings",
		solde: "Balance",
		logout: "Log out",
		// ADD-CREDIT
		buy_credit_title: "Purchase of Credit",
		add_credit_0: "Add",
		add_credit_1: "of credit",
		deny_request: "No I don't want to buy credit.",
		//STATS
		actif_member: "User",
		bot_count: "Bots in circulation",
		payload_count: "Payloads Launched",
		//SHOP PAGE
		pack_premium_title: "Premium",
		pack_premium_text: "The premium version allows you to use our services. We offer you several options to manage your bots efficiently. It is a lifetime payment." ,
		buy: "Buy",
		already_buy: "Already purchased",	
		pack_bot_title: "Discord Bot",
		pack_bot_text: "Distroy provides you with high quality bots verified by email and phone.",
	},
	es: {
		// SETTINGS PAGE
		settings_title: "Parámetros",
		lang_title: "Elija su idioma",
		// SERVER SIDEBAR PAGE
		title_servers: "Lista de servidores",
		name_servers_sidebar: "SERVIDORES",
		acces_shop: "Ir a la tienda",
		premium_text:
			"Debes ser un usuario premium para utilizar esta herramienta",
		close: "Cerrar",
		validate: "Validar",
		add_servers: "Añadir un servidor",
		// BOT SIDEBAR PAGE
		title_bot: "Lista de Bot",
		name_bot_sidebar: "BOT",
		// NAVBAR
		home: "Inicio",
		store: "Tienda",
		add_solde: "Añadir saldo",
		faq: "¿Alguna pregunta?",
		settings: "Parámetros",
		solde: "Equilibrio",
		logout: "Desconectando",
		// ADD-CREDIT
		buy_credit_title: "Compra de crédito",
		add_credit_0: "Añadir",
		add_credit_1: "de crédito",
		deny_request: "No, no quiero comprar crédito.",
		//STATS
		actif_member: "Usuario",
		bot_count: "Bots en circulación",
		payload_count: "Cargas útiles lanzadas",
		//SHOP PAGE
		pack_premium_title: "Premium",
		pack_premium_text: "La versión premium le permite utilizar nuestros servicios. Le ofrecemos varias opciones para gestionar sus bots de forma eficiente. Es un pago de por vida.",
		buy: "Comprar",
		already_buy: "Ya se ha comprado",
		pack_bot_title: "Discord Bot",
		pack_bot_text: "Distroy le proporciona bots de muy buena calidad verificados por correo electrónico y por teléfono.",
	},
	ru: {
		// SETTINGS PAGE
		settings_title: "Параметры",
		lang_title: "Выберите свой язык",
		// SERVER SIDEBAR PAGE
		title_servers: "Список серверов",
		name_servers_sidebar: "СЕРВЕРЫ",
		acces_shop: "Перейти в магазин",
		premium_text:
			"Вы должны быть премиум-пользователем, чтобы использовать этот инструмент",
		close: "Закрыть",
		validate: "Проверить",
		add_servers: "Добавить сервер",
		// BOT SIDEBAR PAGE
		title_bot: "Список ботов",
		name_bot_sidebar: "Робот",
		// NAVBAR
		home: "Главная",
		store: "Магазин",
		add_solde: "Добавить баланс",
		faq: "Есть вопросы?",
		settings: "Параметры",
		solde: "Баланс",
		logout: "Отключение",
		// ADD-CREDIT
		buy_credit_title: "Приобретение кредита",
		add_credit_0: "Добавить",
		add_credit_1: "евро кредита",
		deny_request: "Нет, я не хочу покупать кредит.",
		//STATS
		actif_member: "Пользователь",
		bot_count: "Боты в обращении",
		payload_count: "Запущенные полезные нагрузки",
		//SHOP PAGE
		pack_premium_title: "Премиум",
		pack_premium_text: "Премиум-версия позволяет вам пользоваться нашими услугами. Мы предлагаем вам несколько вариантов эффективного управления вашими ботами. Это пожизненная выплата.",
		buy: "Купить",
		already_buy: "Уже куплено",
		pack_bot_title: "Discord Бот",
		pack_bot_text: "Distroy предоставляет вам ботов очень хорошего качества, проверенных по электронной почте и телефону.",
	}
}