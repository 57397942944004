import { createRouter, createWebHistory } from 'vue-router'
import axios from 'axios';

var end_point = "http://localhost:3000";

const routes = [{
	path: '/',
	name: 'Home',
	component: () => import('../views/Home.vue')
},
{
	path: '/shop',
	name: 'shop',
	component: () => import('../views/Shop.vue')
},
{
	path: "/servers/:id",
	name: "servers",
	component: () => import('../views/Servers.vue')
},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

router.beforeEach(async function (to, from, next) {
	var url = new URL(window.location);
	var code = url.searchParams.get("code") ? url.searchParams.get("code") : false;

	if (code && !token) {
		var authentification = await axios.post(`${end_point}/oauth`, {
			token: code
		});

		if (authentification.data.token) {
			localStorage.setItem("token", authentification.data.token);
			window.location = location.origin;
		}
		else {
			location.href = window.origin
		}

	}

	var token = localStorage.getItem("token") ? localStorage.getItem("token") : null;

	if (!token) {
		location.href = "https://discord.com/oauth2/authorize?client_id=805557414635503636&redirect_uri=http://localhost:8080/&response_type=code&scope=identify%20email&";
	}
	else if (token) {
		next();
	}
})

export default router