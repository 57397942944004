import { createApp } from 'vue';
import { createStore } from 'vuex'

import App from './App.vue';

const app = createApp(App);

import router from './router';

import './assets/scss/main.css';

import 'es6-promise/auto'

import axios from 'axios';

import translation from './translate.js';

import $bus from './event.js';

function getCookie(name) {
	var nameEQ = name + "=";
	var ca = document.cookie.split(";");
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) == " ") c = c.substring(1, c.length);
		if (c.indexOf(nameEQ) == 0)
			return c.substring(nameEQ.length, c.length);
	}
	return null;
}

var end_point = "http://localhost:3000";

app.config.globalProperties.end_point = end_point;
app.config.globalProperties.axios = axios;
app.config.globalProperties.$bus = $bus;
app.config.globalProperties.$translation = translation;

const store = createStore({
	state() {
		return {
			info: 0,
			lang: getCookie("lang") ? getCookie("lang") : "gb",
		}
	},
	mutations: {
		info(state, n) {
			state.info = n;
		},
		lang(state, n) {
			state.lang = n;
		}
	},
})

var me = function () {
	return new Promise(function (resolve) {
		axios.get(`${end_point}/auth/@me`, {
			headers: {
				token: localStorage.getItem("token")
			}
		}).then(function (data) {
			store.commit("info", data.data);
			resolve(1);
		});
	});
}

app.config.globalProperties.me = me;

app.use(store);
app.use(router).mount("#app");